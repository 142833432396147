import React from 'react'
import { Breadcrumbs, Contact, Hero, Page, Services } from '@components'
import { buildLocalizedPath } from '@utils/functions'

const Homepage = ({ pageContext }) => {

  const meta = {
    title: `services.meta.title`,
    description: 'services.meta.description',
  }

  return (
    <Page
      isHomepage
      locale={pageContext.locale}
      meta={meta}
    >
      <Breadcrumbs
        links={[
          { link: buildLocalizedPath('/', pageContext.locale), name: 'header.link.home'},
          { link: buildLocalizedPath('/services/', pageContext.locale), name: 'header.link.services'},
        ]}
      />
      <Hero
        description="services.meta.description"
        subtitle="services.hero.subtitle"
        title="services.hero.title"
      />
      <Services locale={pageContext.locale} />
      <Contact locale={pageContext.locale} />
    </Page>
  )
}

export default Homepage
